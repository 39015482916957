




































































import { Component, Vue, Prop } from "vue-property-decorator";

import { WatcherService } from "@/core/services";
import { WatchedButton } from "@/core/components";

import AppVue from "@/AppVue.vue";
import { CompanyDetailModel } from "@/core/models";
import { roleFlag } from "@/core/constants";
@Component({
  components: {
    WatchedButton,
  },
})
export default class CompanyCardCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: 0 }) projectId: number;
  @Prop({ default: "CompanyDetail" }) companyUrl: string;
  @Prop({ default: new CompanyDetailModel() }) model: CompanyDetailModel;
  toggleWatchLoading = false;

  get companyDetailPath() {
    return this.companyUrl;
  }
  get isLoading() {
    return this.loading;
  }

  get linkedInUrl() {
    if (this.model.linkedInUrl.startsWith("http")) {
      return this.model.linkedInUrl;
    }
    return `https://${this.model.linkedInUrl}`;
  }

  get linkedInUrlText() {
    const maxlength = 27;
    return this.model.linkedInUrl.length > maxlength
      ? this.model.linkedInUrl.slice(0, maxlength - 1) + "…"
      : this.model.linkedInUrl;
  }

  getIcon(role: string) {
    return (roleFlag as any)[role] || "cis-icon-flag";
  }
  get isWatched() {
    const res = WatcherService.isCompanyWatched(this.model.companyId);
    this.model.isWatched = !!res;
    return res;
  }
  toggleWatchList(companyId: number) {
    try {
      this.toggleWatchLoading = true;
      if (!this.model.isWatched) {
        WatcherService.watchCompany(this.model);
      } else {
        WatcherService.unwatchCompany(companyId);
      }
      this.model.isWatched = !this.model.isWatched;
    } finally {
      this.toggleWatchLoading = false;
    }
  }
  async created() {}

  async mounted() {}
}
