import { CompanyDetailView } from ".";
import { CompanyResultsNavigationCpt, ProjectResultsNavigationCpt } from "@/core/components";
import { routeStack } from "@/core/services/routeStack.service";
import { HeaderCpt } from "@/layouts";
import { ProjectDetailView } from "../project";

const rootPath: string = "/Company";

export const routes = [
  {
    path: `${rootPath}/:companyId`,
    name: "CompanyDetail",
    components: {
      default: CompanyDetailView,
      filters: CompanyResultsNavigationCpt,
      map: () => import(/* webpackChunkName: "about" */ "../map/MapView.vue"),
      header: HeaderCpt,
    },
    props: {
      filters: (r: any) => {
        return {
          companyId: r.params.companyId,
          showNavigation: false,
          breadcrumbs: routeStack.routeStack,
        };
      },
    },
    meta: {
      layout: "map",
      breadCrumb: true,
    },
  },
  {
    path: `${rootPath}/:companyId/Project/:projectId`,
    name: "ProjectInCompany",
    components: {
      // I don't know why this component here NEEDS to be
      // a promise otherwise it will not be available
      // on render
      // default: ProjectDetailView,
      // will result in  a white page
      // no error messages - FR
      default: () => Promise.resolve(ProjectDetailView),
      filters: ProjectResultsNavigationCpt,
      map: () => import(/* webpackChunkName: "about" */ "../map/MapView.vue"),
      header: HeaderCpt,
    },
    props: {
      filters: (r: any) => {
        return {
          companyId: r.params.companyId,
          projectId: r.params.projectId,

          // breadcrumbs: routeStack.routeStack,
        };
      },
    },
    meta: {
      layout: "map",
      breadCrumb: true,
    },
  },
];