



















































































































































import { Component, Watch } from "vue-property-decorator";
import { CompanyService, WatcherService, IUIFilters, SearchService, TurnstoneLookupsModel } from "@/core/services";
import { CompanyDetailCpt, CompanyHeaderCpt } from "./components";
import { ProjectCardCpt, ProjectDetailCpt } from "../project";
import AppVue from "@/AppVue.vue";
import {
  CompanyDetailModel,
  SearchResultContainer,
  CompanyModel,
  ProjectSearchResultModel,
  Field,
  SearchModel,
} from "@/core/models";
import { GeocodingService } from "@/core/services";
import { MapEvents } from "../map/MapView.vue";
import { SearchResultItemCpt, FilterStageCpt, FilterLocationCpt, SearchResultDetailsCpt } from "../search";
import { routeStack } from "@/core/services/routeStack.service";
import { BreadCrumbsCpt } from "@/core/components";
import TrackingNotesCpt from "../tracking/components/TrackingNotesCpt.vue";
import { CompanyTagListCpt } from "../tags";

@Component({
  components: {
    BreadCrumbsCpt: () => Promise.resolve(BreadCrumbsCpt),
    SearchResultItemCpt: () => Promise.resolve(SearchResultItemCpt),
    ProjectCardCpt: () => Promise.resolve(ProjectCardCpt),
    CompanyDetailCpt: () => Promise.resolve(CompanyDetailCpt),
    FilterStageCpt: () => Promise.resolve(FilterStageCpt),
    FilterLocationCpt: () => Promise.resolve(FilterLocationCpt),
    TrackingNotesCpt: () => Promise.resolve(TrackingNotesCpt),
    ProjectDetailCpt: () => Promise.resolve(ProjectDetailCpt),
    SearchResultDetailsCpt: () => Promise.resolve(SearchResultDetailsCpt),
    CompanyTagListCpt: () => Promise.resolve(CompanyTagListCpt),
    CompanyHeaderCpt: () => Promise.resolve(CompanyHeaderCpt),
  },
  async beforeRouteUpdate(to, from, next) {
    const c = this as CompanyDetailView;
    try {
      this.$eventHub.$emit(MapEvents.Clear);
      c.loading = true;
      c.companyId = Number(to.params.companyId);
      c.companyModel = await CompanyService.getCompany(c.companyId);
      c.updateActiveTab(c.activeTab, c.activeTab);

      c.setLocation();
      c.companyModel.isWatched = WatcherService.isCompanyWatched(c.companyId);
      c.updateTitle(c.companyModel.nameAndAddress);
      routeStack.push(to);
      routeStack.addTitle(to, c.companyModel.name);
    } finally {
      c.loading = false;
    }
    next();
  },
  async beforeRouteEnter(to, from, next) {
    // before loading the route, check that the cache is built
    next();
  },
})
export default class CompanyDetailView extends AppVue {
  loading: boolean = false;
  companyId: number = 0;
  lookups: TurnstoneLookupsModel = new TurnstoneLookupsModel();
  model: Array<SearchResultContainer<ProjectSearchResultModel>> = [];
  companyModel: CompanyDetailModel = new CompanyDetailModel();
  activeTab: string = "projects";
  selectedProject: ProjectSearchResultModel = new ProjectSearchResultModel();
  skip: number = 0;
  filters: IUIFilters = SearchService.defaultFilter();
  showFiltersMobile = false;
  totalResults: number;
  hiddenResults: number = 0;
  companyConnectionsId = 0;
  companyConnections: any[] = [];
  $refs: {
    connectionsTable: HTMLFormElement;
  };
  get message() {
    return `There are ${this.hiddenResults} projects
    that your current subscription cannot see.`;
  }
  get filtersMobileClass() {
    return this.showFiltersMobile ? "show-mobile" : "";
  }
  @Watch("companyModel.isWatched")
  goToTracking(newValue: any, oldValue: any) {
    if (!!newValue && oldValue === false) {
      this.activeTab = "tracking";
    }
  }

  @Watch("activeTab")
  async updateActiveTab(newValue: string, oldValue: string) {
    if (newValue === "connections") {
      if (this.companyConnectionsId === 0 || this.companyId !== this.companyConnectionsId) {
        this.loading = true;

        this.companyConnections = [];
        this.companyConnections = (await CompanyService.getCompanyConnections(this.companyId)).map((c: any) => {
          c.projectList.forEach((x: any) => (x.document.selected = false));
          return c;
        });
        this.companyConnectionsId = this.companyId;
        this.$nextTick(() => {
          this.loading = false;
        });
      }
    }
  }
  clickConnection(row: any, column: any, cell: any, event: any) {
    this.$refs.connectionsTable.toggleRowExpansion(row);
  }
  closeProjectPeek() {
    this.selectedProject = new ProjectSearchResultModel();
    this.$eventHub.$emit(MapEvents.Refresh);
  }
  mouseOverItem({
    overStatus,
    model,
  }: {
    overStatus: boolean;
    model: SearchResultContainer<ProjectSearchResultModel>;
  }) {
    if (overStatus && model.document.location) {
      this.focusToProject(
        model.document.projectId,
        model,
        model.document.location.latitude,
        model.document.location.longitude,
      );
    }
  }
  async created() {
    const config = await GeocodingService.getKey();
    GeocodingService.setKey(config.geocodeMapKey);
    this.$eventHub.$emit(MapEvents.Clear);
    this.loading = true;
    await WatcherService.companyList(0);
    await WatcherService.projectList(0);
    this.lookups = await SearchService.getLookups();

    this.companyId = Number(this.$route.params.companyId);

    this.companyModel = await CompanyService.getCompany(this.companyId);
    this.setLocation();
    this.companyModel.isWatched = WatcherService.isCompanyWatched(this.companyId);
    super.updateTitle(this.companyModel.nameAndAddress);
    this.$nextTick(() => {
      routeStack.push(this.$route);
      routeStack.addTitle(this.$route, this.companyModel.name);
    });
    await this.loadProjects();
    this.addProjectMarkers(this.model);
    this.loading = false;
  }

  async setLocation() {
    try {
      if (!!this.companyModel.location && this.companyModel.location.length === 2) {
        this.$eventHub.$emit(MapEvents.AddCompanyMarker, {
          latLong: {
            lat: this.companyModel.location[0],
            lng: this.companyModel.location[1],
          },
          type: "orange",
          zoom: 18,
        });
      } else {
        const locationResult = await GeocodingService.search(this.companyModel.fullAddress);
        const l = locationResult.results[0].geometry.location;
        this.companyModel.googleLocation = [l.lat, l.lng];
        await CompanyService.updateCompanyLocation(this.companyId, l);
        this.$eventHub.$emit(MapEvents.AddCompanyMarker, {
          latLong: this.companyModel.googleLocation,
          type: "orange",
          zoom: 16,
        });
      }
    } catch {}
  }
  async loadProjects(skip?: number) {
    const results: Field[] = SearchService.buildProjectFilter(
      this.filters.trackingFilter,
      this.filters.geoRange,
      this.filters.value.min,
      this.filters.value.max,
      this.filters.stage,
      this.filters.categories,
      [this.filters.dateUpdated],
      this.filters.counties,
      this.filters.isSelfBuild,
      this.filters.roles,
      this.filters.polyline,
      this.filters.fundingType,
      this.filters.constructionType,
      this.filters.planningAuthority,
      this.filters.materials,
      this.filters.floorArea,
      this.filters.numberOfParkingSpaces,
      this.filters.siteArea,
      this.filters.numberOfStoreys,
      this.filters.numberOfUnits,
      this.filters.applicationType,
      this.filters.isFramework,
      this.filters.appealStatusList,
      this.filters.publicTag,
    );
    const filter = new SearchModel();
    results.forEach((x) => filter.fields.push(x));

    const projects = await CompanyService.getCompanyProjects(this.companyId, skip, filter);
    if (!!skip && skip > 0) {
      projects.results.forEach((item) => {
        item.document.isWatched = WatcherService.isProjectWatched(Number(item.document.projectId));
        item.document.selected = false;
        this.model.push(item);
      });
      this.addProjectMarkers(projects.results);
    } else {
      this.model = projects.results;
      this.totalResults = projects.totalRecords;
      this.hiddenResults = (projects.totalRecordsUnfiltered || 0) - projects.totalRecords;
      this.model.forEach((x) => {
        x.document.isWatched = WatcherService.isProjectWatched(Number(x.document.projectId));
        x.document.selected = false;
      });
    }
  }
  unwatchCompany(item: CompanyModel) {
    this.$nextTick(() => {
      item.isWatched = false;
    });
  }
  watchCompany(item: CompanyModel) {
    this.$nextTick(() => {
      item.isWatched = true;
    });
  }
  unwatchProject(item: any) {
    this.$nextTick(() => {
      item.document.isWatched = false;
    });
  }
  watchProject(item: any) {
    this.$nextTick(() => {
      item.document.isWatched = true;
    });
  }
  countiesUpdated(selectedItems: string[]) {
    this.filters.counties = selectedItems;
    this.filtersUpdated();
  }
  stagesUpdated(selectedStages: string[]) {
    this.filters.stage = selectedStages;
    this.filtersUpdated();
  }
  filtersUpdated() {
    this.loadProjects();
    this.showFiltersMobile = false;
  }
  addProjectMarkers(results: Array<SearchResultContainer<ProjectSearchResultModel>>) {
    const locations = results.filter((x) => !!x.document.location).map((x) => x.document);
    if (locations.length > 0) {
      const payload = locations.map((x) => ({
        resultId: Number(x.projectId),
        data: x,
        latLong: {
          lat: x.location.latitude,
          lng: x.location.longitude,
        },
        zoom: 13,
        pin: true,
        text: x.title,
      }));
      this.$eventHub.$emit(MapEvents.AddMarkerCollectionNoFly, payload);
    }
  }

  focusToProject(projectId: string | number, data: any, lat: any, lng: any) {
    this.$nextTick(() => {
      this.$eventHub.$emit(MapEvents.Focus, {
        resultId: projectId,
        data,
        pin: true,
        zoom: 18,
        latLong: {
          lat,
          lng,
        },
      });
    });
  }

  selectItem(item: SearchResultContainer<ProjectSearchResultModel>) {
    this.closeProjectPeek();

    this.model.forEach((x) => (x.document.selected = false));
    // to do in connections
    this.companyConnections.forEach((x) => {
      const enabled = x.projectList.filter((t: any) => t.document.selected);
      if (!!enabled) {
        x.projectList.forEach((p: any) => {
          p.document.isWatched = WatcherService.isProjectWatched(Number(item.document.projectId));
          p.document.selected = false;
        });
      }
    });

    this.selectedProject = item.document;
    item.document.selected = true;
    this.$eventHub.$emit("openDetailView");
    if (this.selectedProject.location) {
      this.focusToProject(
        item.document.projectId,
        item.document,
        item.document.location.latitude,
        item.document.location.longitude,
      );
    } else {
      this.$nextTick(() => {
        this.$eventHub.$emit(MapEvents.CloseAllPopups);
      });
    }
  }

  async loadMore() {
    this.skip = this.skip + 20;
    this.loadProjects(this.skip);
  }
  async mounted() {
    if (this.$isMobile()) {
      this.activeTab = "general";
    }
  }
}
