





















import { Component, Vue, Prop } from "vue-property-decorator";

import {} from "@/core/services";
import { CompanyModel } from "@/core/models";

import AppVue from "@/AppVue.vue";

export class CompanyList {
  results: CompanyModel[];
  totalRecords: number;
}

@Component({
  components: {},
})
export default class CompanyListCpt extends AppVue {
  @Prop({ default: true }) loading: boolean;

  get isLoading() {
    return this.loading;
  }

  model: CompanyList = { results: [], totalRecords: 0 };

  async created() {
    this.loading = false;
  }

  async mounted() {}
}
