



















































import { Component, Vue, Prop } from "vue-property-decorator";

import { WatcherService } from "@/core/services";
import { CompanyHeaderCpt } from ".";
import { CompanyModel, CompanyDetailModel } from "@/core/models";
import { WatchedButton } from "@/core/components";
import ProjectLabeledSectionCpt from "@/modules/project/components/ProjectLabeledSectionCpt.vue";
import AppVue from "@/AppVue.vue";

@Component({
  components: {
    WatchedButton: () => Promise.resolve(WatchedButton),
    CompanyHeaderCpt: () => Promise.resolve(CompanyHeaderCpt),
    ProjectLabeledSectionCpt: () => Promise.resolve(ProjectLabeledSectionCpt),
  },
})
export default class CompanyDetailCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: false }) model: CompanyDetailModel;
  toggleWatchLoading = false;
  async toggleWatchList(model: CompanyDetailModel) {
    try {
      this.toggleWatchLoading = true;
      const companyId = Number(model.companyId);
      if (!model.isWatched) {
        await WatcherService.watchCompany(model);
        this.$emit("watchedCompany", this.model);
        this.$notify({
          title: "Tracking",
          message: `Now tracking ${model.name}`,
          type: "success",
        });
      } else {
        await WatcherService.unwatchCompany(companyId);
        this.$emit("unwatchedCompany", this.model);
        this.$notify({
          title: "Tracking",
          message: `No longer tracking ${model.name}`,
          type: "success",
        });
      }
    } finally {
      this.toggleWatchLoading = false;
    }
  }
  async created() {}

  async mounted() {}
}
