




















import { Component, Vue, Prop } from "vue-property-decorator";
import { SectorIconCpt } from "@/core/components";
import { CompanyDetailModel } from "@/core/models";

import AppVue from "@/AppVue.vue";

@Component({
  components: {
    SectorIconCpt,
  },
})
export default class CompanyHeaderCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: {} }) model: CompanyDetailModel;

  get isLoading() {
    return this.loading;
  }

  async created() {}

  async mounted() {}
}
